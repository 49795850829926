import firebase from 'firebase'
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyDP79Y3EsWIhJI46V6Wur36ufgtVZJaN3k",
    authDomain: "isacsons-tradgardshandel.firebaseapp.com",
    databaseURL: "https://isacsons-tradgardshandel.firebaseio.com",
    projectId: "isacsons-tradgardshandel",
    storageBucket: "isacsons-tradgardshandel.appspot.com",
    messagingSenderId: "711661132626",
    appId: "1:711661132626:web:f64157e23f0213de45b88b"

};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();