<template>
    <v-card class="product" max-width="344">
        <v-img class="white--text align-end" height="17rem" :src=imgSrc>
            <v-card-title>{{title}}</v-card-title>
        </v-img>

        <v-card-text class="text--primary">
            {{description}}
        </v-card-text>

        <v-card-text v-if="colors" class="pb-0">
            <v-chip-group
            v-model="selectedColor"
            mandatory
            column>
            <v-chip outlined small v-for="color in colors" :key="color.code" :color="color.code" filter>
                {{color.name}}
            </v-chip>
        </v-chip-group>
        </v-card-text>

        <v-card-subtitle class="pb-0">
            {{`${price} SEK` }}
        </v-card-subtitle>

        <v-card-actions>
            <v-btn color="orange" text @click="addToCart()" :disabled=!inSeason>
                Lägg i varukorg
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props : [
            'title', 'imgSrc', 'description', 'price', 'id',  'inSeason', 'colors'
        ],
        name : 'Product',
        data: () => ({
            selectedColor: undefined 
        }),
        methods : {
            addToCart() {
                const {id, title, imgSrc, price, colors, selectedColor} = this
                const productObject = {id, title, imgSrc, price}
                if (colors) {
                    productObject.selectedColorObject = colors[selectedColor]
                } else {
                    productObject.selectedColorObject = {name: '', code: ''}
                }
                this
                    .$store
                    .commit('addToCart', productObject)
            }
        }
    }
</script>

<style>
    @media only screen and (max-width: 768px) {
        .product {
            margin: 1rem 0!important;
        }
    }
    .product {
        margin: 1.2rem;
    }
    .v-card__title {
        background-color: rgba(0,0,0, 0.55)
    }
</style>