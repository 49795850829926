<template>
    <v-card class="overflow-hidden appBar" style="position: relative">
        <v-app-bar
            dark
            style="background-color: rgba(49,54,56,1.0) !important"
            flat
            prominent
            shrink-on-scroll
            fixed
            src="../assets/plant.jpg"
            fade-img-on-scroll>
            <template v-slot:img="{ props }">
                <v-img
                    v-bind="props"
                    gradient="to top right, rgba(1,50,32,.4), rgba(0,0,0,.6)"></v-img>
            </template>

            <v-toolbar-items class="appBar__title ml-4">
                <img alt="Logo" class="appBar__logo" @click="$router.push('/')" src="https://firebasestorage.googleapis.com/v0/b/isacsons-tradgardshandel.appspot.com/o/Isacsson_logo.png?alt=media&token=67d95ef2-ca50-4aa6-a658-37bb2e65df1d" style="height: inherit;">  
            </v-toolbar-items>  

            <v-spacer></v-spacer>

            <div class="appBar__search">
                <v-btn @click="search()" class="appBar__searchIcon" icon>
                    <v-icon>
                        mdi-magnify
                    </v-icon>
                </v-btn>
                        <v-text-field
                            hide-details="true"
                            dense
                            rounded
                            outlined
                            v-model="searchParam"
                            label="Sök"
                            v-on:keyup.enter="search()"
                        ></v-text-field> 
            </div>

            <CheckoutDropdown/>

            <template v-if="!loading" v-slot:extension>
                <v-menu offset-y v-for="item in Object.values(tabs)" :key="item.title">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark text v-bind="attrs" v-on="on">
                            {{item.title}}
                        </v-btn>
                    </template>
                    <div></div>
                    <v-list class="appBar__dropDownMenu" v-if="item.data">
                        <div
                            class="appBar__dropDownMenuItem"
                            v-for="(category, index) in Object.values(item.data)"
                            :key="index">
                            <v-subheader style="font-size: 1.1rem; font-weight: bold" v-if="category.translation">{{category.translation}}</v-subheader>
                            <v-list-item v-for="item in category.data" :key="item.title" :disabled="!item.hasProducts" class="appBar__listItem">
                                <v-divider></v-divider>
                                <v-list-item-title
                                    @click="navigateToCategory(category, item)">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
                <v-btn dark text @click="$router.push(`/advice`)">Växtråd</v-btn>
                <v-btn dark text @click="$router.push(`/faq`)">Frågor & Svar</v-btn>
            </template>
        </v-app-bar>
    </v-card>
</template>
<script>
    import CheckoutDropdown from './CheckoutDropdown';
    import db from '../../firebase';

    export default {
        name : 'AppBar',
        components : {
            CheckoutDropdown
        },
        created : async function () {
            await this.fetchCategories()
            this.loading = false
        },
        methods : {
            search() {
                this.$router.push({path: '/search', query: { filter: this.searchParam }}); 
                this.searchParam = ''; 
            },
            async fetchCategories() {
                try {
                    db
                        .collection('tabKeys')
                        .get()
                        .then(qs => {
                            qs.forEach(doc => {
                                this.$set(this.tabs, doc.id, {
                                    ...doc.data(),
                                    data: {}
                                })
                            })
                        })

                    db
                        .collection('categories')
                        .get()
                        .then((qs) => {
                            qs.forEach((doc) => {
                                const {id} = doc;
                                const {sectionKey, title, tabKey, hasProducts} = doc.data()
                                let sectionRefId, sectionTranslation
                                sectionKey
                                    .get()
                                    .then(sectionRef => {
                                        sectionRefId = sectionRef.id
                                        sectionTranslation = sectionRef.data().translation
                                tabKey
                                    .get()
                                    .then(tabRef => {
                                        if (!this.tabs[tabRef.id].data[sectionRefId]) this.tabs[tabRef.id].data[sectionRefId] = {translation: sectionTranslation, data: [{id, title, hasProducts}]}
                                        else this.tabs[tabRef.id].data[sectionRefId].data.push({id, title, hasProducts})
                                    })
                                })
                            })
                        })
                } catch (error) {
                    console.log(error)
                }
            },
            toPath(string) {
                string = string
                    .replace(/å/g, 'a')
                    .replace(/Å/g, 'A')
                    .replace(/ä/g, 'a')
                    .replace(/Ä/g, 'A')
                    .replace(/ö/g, 'o')
                    .replace(/Ö/g, 'O')
                return string.toLowerCase()
            },
            async navigateToCategory(category, item) {
                localStorage.setItem("categoryId", item.id);
                if (category.translation) {
                    return this.$router.push(`/products/${this.toPath(category.translation)}/${this.toPath(item.title)}`)
                }
                return this.$router.push(`/products/${this.toPath(item.title)}`)
            }
        },
        data : () => ({
            searchParam: '',
            loading: true, tab: null,
            tabs: {},
        })
    }
</script>

<style>
    /* Hide scrollbar for Chrome, Safari and Opera */
    .appBar::-webkit-scrollbar, .appBar__dropDownMenu::-webkit-scrollbar, .v-menu__content::-webkit-scrollbar, .v-toolbar__extension::-webkit-scrollbar {
        display: none !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .appBar, .appBar__dropDownMenu, .v-menu__content, .v-toolbar__extension {
        -ms-overflow-style: none !important;  /* IE and Edge */
        scrollbar-width: none !important;  /* Firefox */
    }
    @media only screen and (max-width: 768px) {
        .appBar__logo {
            width: inherit;
            height: 3rem !important
        }
    }
    .appBar__title :hover {
        cursor: pointer;
    }
    .appBar__search {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.1rem !important;
        margin-right: 1rem !important;
        max-width: 23.5rem
    }
    .appBar__searchIcon {
        z-index: 1000;
        right: 0;
        position: absolute;
    }
    .appBar__search .v-input__slot {
        background-color: rgba(255,255,255, 0.13) !important;
    }
    @media only screen and (max-width: 768px) {
        .appBar__dropDownMenu {
            display: block !important;
        }
    }
    .appBar__dropDownMenu {
        background-color: 'white';
        display: flex;
        overflow: scroll;
        max-height: 21rem;
    }
    .v-menu__content {
        background: white 
    }
    .v-toolbar__extension {
        overflow: scroll
    }
    .appBar__listItem:hover {
        cursor: pointer;
    }
</style>