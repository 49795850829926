<template>
<v-dialog
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
            Beställning mottagen
        </v-card-title>

        <v-card-text class="mt-5 mb-5">
            Tack för din beställning, vi kontaktar dig med bekräftelse inom 24h
            Betalning sker vid upphämtning/leverans
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeModal()"
          >
          Okej
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props : [
            'showDialog'
        ],
        name : 'Dialog',
        methods: {
            closeModal() {
                this.$emit("close-modal");
            }
        }
    }
</script>