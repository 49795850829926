<template>
    <div class="faq">
        <h1 class="faq__header">Frågor & Svar</h1>
        <v-expansion-panels accordion>
            <v-expansion-panel
                v-for="(question, index) in questions"
                :key="index"
                class="faq__questionWrapper">
                <v-expansion-panel-header class="faq__questionHeader">
                    <p>{{question.title}}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div
                        v-for="body in question.body"
                        :key="body"
                        class="faq__questionBody"
                        v-html="body"></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import db from '../../firebase';

    export default {
        data : () => ({questions: []}),
        created : function () {
            try {
                db
                    .collection('faq')
                    .get()
                    .then(qs => {
                        qs.forEach(doc => {
                            this
                                .questions
                                .unshift(doc.data())
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }
</script>

<style>
    @media only screen and (max-width: 768px) {
        .faq {
            max-width: 95% !important
        }
    }
    .faq {
        width: auto;
        max-width: 55%;
        padding: 2rem 1rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .faq__header {
        text-align: center;
        margin: 3rem 0rem 2rem 0rem
    }
    .faq__questionHeader {
        font-weight: 600 !important;
        font-size: 1rem
    }
    .faq__questionHeader p {
        margin-bottom: 0 !important;
    }
    .faq__questionWrapper {
        padding: 0.875rem 1.5rem;
        margin: 1rem 0;
    }
    .faq__questionBody {
        margin: 0.875rem 0;
    }

</style>