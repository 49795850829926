<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="377"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }" class="checkoutDropdown">
        <v-btn
            icon
          v-bind="attrs"
          v-on="on"
        >
        <v-badge
          v-if="!emptyCart"
          color="green"
          :content="numberOfProducts"
          overlap
        >
            <v-icon>mdi-cart</v-icon>
        </v-badge>
            <v-icon v-else>mdi-cart</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-subheader>Produkter</v-subheader>
          <div v-if="emptyCart" class="ma-5 text-center"> Inga Produkter!</div>
            <v-list-item v-for="item in $store.state.products" :key="item.key">
              <v-list-item-avatar>
                <img
                  :src="item.imgSrc"
                  alt="Plant picture"
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{`${item.title}`}}
                  <v-chip outlined x-small :color="item.selectedColorObject.code" v-if="item.selectedColorObject.code">
                      {{item.selectedColorObject.name}}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{`${item.quantity * item.price} SEK`}}</v-list-item-subtitle>
              </v-list-item-content>

              <div class="checkoutDropdown__stepper">
                <v-btn icon @click="prependIconCallback(item.id, item.selectedColorObject)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                {{item.quantity}}
                <v-btn icon @click="appendIconCallback(item.id, item.selectedColorObject)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

            <v-btn icon color="red" danger text @click="$store.commit('removeFromCart', item)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-list-item>

          <div class="checkoutDropdown__totalWrapper">
            <v-divider></v-divider>
            <div class="checkoutDropdown__totalTextWrapper">
              <h3>Totalt</h3>
              <span>{{`${getTotalValue} SEK`}}</span>
            </div>
          </div>
        </v-list>

        <v-divider class="mx-4"></v-divider>

        <div class="checkoutDropdown__inputContainer">
          <v-form v-model="valid" ref="form">
              <v-text-field
                dense
                label="Email"
                :disabled="emptyCart"
                v-model="email"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                dense
                label="Namn"
                v-model="name"
                :disabled="emptyCart"
                :rules="[v => !!v || 'Namn är obligatoriskt']"
                required
              ></v-text-field>
              <v-text-field
                dense
                label="Adress"
                v-model="adress"
                :disabled="emptyCart"
                :rules="[v => !!v || 'Adress är obligatoriskt']"
              ></v-text-field>
              <v-text-field
                dense
                label="Telefonnummer"
                v-model="phoneNumber"
                :disabled="emptyCart"
                :rules="[v => !!v || 'Telefonnummer är obligatoriskt']"
                required
              ></v-text-field>
              <v-radio-group
                    mandatory
                    :disabled="emptyCart"
                    v-model="deliveryMethod"
                    row>
                    <v-radio
                      label="Upphämtning"
                      value="upphämtning"
                    ></v-radio>
                    <v-radio
                      label="Leverans (150:- extra)"
                      value="leverans"
                    ></v-radio>
              </v-radio-group>

              <v-checkbox
                v-model="checkbox"
                :disabled="emptyCart"
                :rules="[v => !!v || 'För att skicka beställningen behöver du bekräfta!']"
                label="Bekräfta beställning"
                required
              ></v-checkbox>
            </v-form>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="menu = false"
          >
            Avbryt
          </v-btn>
          <v-btn
            :disabled="emptyCart"
            type="submit"
            color="primary"
            text
            @click="handleSubmit"
          >
            Skicka beställning 
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <Dialog :showDialog="dialog" @close-modal="closeModal()"/>
  </div>
</template>

<script>
    import * as api from '../api';
    import Dialog from './Dialog';
    import db from '../../firebase';
    export default {
        name : 'CheckoutDropdown',
        components: {
          Dialog
        },
        created() {
          const checkoutState = JSON.parse(localStorage.getItem('checkoutState'))
          this.$store.commit('rebuildCheckout', checkoutState)
        },
        data : () => ({
            dialog: false,
            valid: false,
            deliveryMethod: null,
            checkbox: false,
            menu: false,
            name: '',
            adress: '',
            email: '',
            phoneNumber: '',
            emailRules: [
              v => !!v || 'E-mail är obligatoriskt',
              v => /.+@.+/.test(v) || 'E-mail formatet är fel',
            ],
        }),
        computed: {
          getTotalValue() {
            if (this.totalValue) {
              if (this.deliveryMethod === 'leverans')
                return this.totalValue + 150
              return this.totalValue
            }
            return 0
          },
          emptyCart() {
            const {products} = this.$store.state
            return !products || this.$store.state.products.length === 0
          },
          totalValue() {
            const {products} = this.$store.state
            return products && this.$store.state.products.reduce((a, b) => a + (b['quantity'] * b['price'] || 0), 0)
          },
          numberOfProducts() {
            const {products} = this.$store.state
            return products && this.$store.state.products.reduce((a, b) => a + (b['quantity'] || 0), 0) 
          }
        },
        methods: {
          appendIconCallback(id, selectedColorObject) {
            return this.$store.commit('increaseProductQuantity', {id, selectedColorObject})
          },
          prependIconCallback(id, selectedColorObject) {
            return this.$store.commit('decreaseProductQuantity', {id, selectedColorObject})
          },
          validate () {
            return this.$refs.form.validate()
          },
          getMessage() {
            const products = this.$store.state.products.map(product => {return `ProduktID: ${product.id},\r\n Titel: ${product.title},\r\n Antal: ${product.quantity}, \r\n Färg: ${product.selectedColorObject.name},\r\n Pris per enhet: ${product.price} \r\n\r\n`})
            return `Person med följande kontaktuppgifter \r\n Namn: ${this.name}, Adress: ${this.adress} vill beställa produkterna: \r\n\r\n ${products} med leveranssätt ${this.deliveryMethod}`
          },
          async sendEmail() {
            const self = this
            db.collection("orders").add({products: this.$store.state.products, email: this.email, number: this.phoneNumber, name: this.name, adress: this.adress, deliveryMethod: this.deliveryMethod })
            .then(async function(docRef) {
              const {id} = docRef
              const response = await api.sendEmail({number: self.phoneNumber, email: self.email, message: self.getMessage(), ordernumber: id});
              if (response.status === 200) {
                  self.dialog = true
                  self.$store.commit('clearCheckout')
                  localStorage.removeItem('checkoutState')
              }
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
          },
          handleSubmit() {
            if (this.validate()) this.sendEmail()
          },
          closeModal() {
            this.dialog = false
            window.location.href="/"
          }
        }
    }
</script>

<style>
.v-menu__content {
  max-height: 89% !important;
  overflow: scroll;
}
.checkoutDropdown__inputContainer {
  padding: 0.875rem 1rem
}
.checkoutDropdown__totalWrapper{
  padding: 1rem;
}
.checkoutDropdown__totalTextWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 0rem;
}
.checkoutDropdown__stepper {
  margin: 0.3rem
}
.checkoutDropdown__stepper .v-input__slot {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>