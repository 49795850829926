import Vue from 'vue'
import Router from 'vue-router'
import LandingPage from '../components/LandingPage'
import Products from '../components/Products'
import Advice from '../components/Advice'
import Search from '../components/Search'
import FAQ from '../components/FAQ'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            component: LandingPage
        }, {
            path: '/products/:sectionId/:pathId',
            component: Products
        },
        {
            path: '/products/:pathId',
            component: Products
        }, 
        {
            path: '/advice',
            component: Advice
        }, {
            path: '/faq',
            component: FAQ
        }, {
            path: '/search',
            name: 'search',
            component: Search
        }
    ]
})
