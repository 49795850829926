<template>
    <div class="landingPage">
        <div class="landingPage__images section">
            <v-card
                v-for="content in landingContent"
                :key="content.id"
                :class="`landingPage__imageWrapper${content.data.position}`"
                @click="navigateToCategory(content)"
                :style="{ 'background-image': 'url(' + content.data.imgSrc + ')' }">
                <div class="landingPage__contentWrapper">
                    <div class="landingPage__imageTextWrapper">
                        <h2 class="landingPage__imageText">{{content.data.title}}</h2>
                    </div>
                </div>
            </v-card>
        </div>
        <v-divider></v-divider>
        <div class="landingPage__didYouKnow section">
            <h2>Visste du att</h2>
            <p>
                Från 15 mars till 30 juni hittar ni ett brett utbud av säsongsblommor, växter,
                perenner, buskar, träd, kryddväxter, sättpotatis, krukor och allt annat som
                behövs till trädgården! Vi samarbetar med flera västsvenska och säljer
                högkvalitativa lokalodlade växter i så stor utsträckning som möjligt. Vi är
                stolta återförsäljare av Emmaljunga Torvmulls jord och gödselprodukter och du
                hittar större delen av deras fina sortiment hos oss!
                <br>
                <br>
                Från 1 oktober fram till jul säljer vi härliga säsongsblommor, handgjorda gravdekorationer och allt som hör julen till med blommor, dörrkransar, granar, grupper och ris!
            </p>
        </div>
    </div>
</template>

<script>
    import db from '../../firebase';
    export default {
        name : 'LandingPage',
        created : async function () {
            await this.fetchContent()
            this.loading = false
        },
        data : () => ({loading: true, landingContent: []}),
        methods : {
            toPath(string) {
                string = string
                    .replace(/å/g, 'a')
                    .replace(/Å/g, 'A')
                    .replace(/ä/g, 'a')
                    .replace(/Ä/g, 'A')
                    .replace(/ö/g, 'o')
                    .replace(/Ö/g, 'O')
                return string.toLowerCase()
            },
            navigateToCategory(content) {
                const {categoryId, section, category} = content
                localStorage.setItem("categoryId", categoryId);
                return this
                    .$router
                    .push(`/products/${this.toPath(category)}/${this.toPath(section)}`)
            },
            async fetchContent() {
                db
                    .collection('landing')
                    .get()
                    .then((qs) => {
                        qs.forEach(doc => {
                            const {category} = doc.data()
                            category
                                .get()
                                .then(categoryRef => {
                                    const categoryId = categoryRef.id
                                    const {title, sectionKey} = categoryRef.data()
                                    sectionKey
                                        .get()
                                        .then(sectionRef => {
                                            const {translation} = sectionRef.data()
                                            this
                                                .landingContent
                                                .push({
                                                    id: doc.id,
                                                    data: doc.data(),
                                                    section: translation,
                                                    category: title,
                                                    categoryId
                                                })
                                        })
                                })
                        })
                    });
            }
        }
    }
</script>

<style>
    @import url( 'https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
    @media only screen and (max-width: 768px) {
        .landingPage__images {
            display: flex !important;
            flex-direction: column !important;
        }
    }
    .section {
        padding: 2rem;
    }
    .landingPage {
        padding: 0 3rem;
    }
    .landingPage__didYouKnow {
        margin: 3rem auto;
        max-width: 55rem;
    }
    .landingPage__images {
        margin: 3rem auto;
        display: grid;
        width: 80%;
        min-height: 40rem;
        height: 89%;
        padding-bottom: 2rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1.5rem;
    }
    .landingPage__imageWrapper1,
    .landingPage__imageWrapper2,
    .landingPage__imageWrapper3 {
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        background-size: cover;
        background-position: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
    }
    .landingPage__imageWrapper1:hover,
    .landingPage__imageWrapper2:hover,
    .landingPage__imageWrapper3:hover {
        cursor: pointer;
        scale: 1.005;
        opacity: 0.89;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
    }
    .landingPage__contentWrapper {
        border: 1px solid white;
        max-width: 89%;
        padding: 1rem;
        text-align: center;
    }
    .landingPage__imageTextWrapper {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        background-color: rgba(255,255,255, 1.0);
        padding: 3rem;
        width: auto;
    }
    .landingPage__imageWrapper1 {
        grid-row: 1/4;
    }
    .landingPage__imageWrapper2 {
        grid-row: 1/2;
    }
    .landingPage__imageWrapper3 {
        grid-row: 2/4;
    }
    .landingPage__imageText {
        margin: 0 auto;
        letter-spacing: 0.4rem;
        font-family: 'Josefin Sans', sans-serif;
        color: rgba(43,43,43, 0.89);
    }

</style>