<template>
    <div class="Search">
        <h1>Sökresultat</h1>
        <div class="Search__list">
            <Product
                v-for="product in products"
                :key="product.id"
                :id="product.id"
                :title="product.data.title"
                :imgSrc="product.data.imgSrc"
                :colors="product.data.colors"
                :description="product.data.description"
                :inSeason="product.data.inSeason"
                :price="product.data.price"/>
        </div>
    </div>
</template>

<script>
    import Product from '../components/Product';
    import db from '../../firebase';
    export default {
        components : {
            Product
        },
        data() {
            return {products: [], cachedProducts: []}
        },
        created : function () {
            this.search()
        },
        methods : {
            search() {
                const {filter} = this.$route.query
                db
                    .collection('products')
                    .get()
                    .then((qs) => {
                        qs.forEach(doc => {
                            const {title, description} = doc.data()
                            if ((title && title.includes(filter)) || (description && description.includes(filter))) {
                                this
                                    .products
                                    .push({
                                        id: doc.id,
                                        data: doc.data()
                                    })
                            }
                            this
                                .cachedProducts
                                .push({
                                    id: doc.id,
                                    data: doc.data()
                                })
                        })
                    });
            },
            searchCached() {
                const {filter} = this.$route.query
                this.products = []
                this
                    .cachedProducts
                    .map(product => {
                        const {title, description} = product.data
                        if ((title && title.toLowerCase().includes(filter.toLowerCase())) || (description && description.toLowerCase().includes(filter.toLowerCase()))) {
                            this
                                .products
                                .push(product)
                        }
                    })
            }
        },
        watch : {
            '$route.query.filter': {
                handler: function () {
                    this.searchCached()
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style>
    .Search {
        padding: 3rem 1rem;
    }
    .Search__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

</style>