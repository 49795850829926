<template>
    <div class="Products">
        <h1 class="Products__header">Produkter</h1>
        <div v-if="products.length > 0" class="Products__list">
            <Product
                v-for="product in products"
                :key="product.id"
                :id="product.id"
                :title="product.data.title"
                :imgSrc="product.data.imgSrc"
                :colors="product.data.colors"
                :description="product.data.description"
                :inSeason="product.data.inSeason"
                :price="product.data.price"/>
        </div>
    </div>
</template>

<script>
    import Product from './Product';
    import db from '../../firebase';

    export default {
        name : 'Products',
        components : {
            Product
        },
        data() {
            return {products: []}
        },
        created : async function () {
            this.fetchProducts()
        },
        methods : {
            fetchProducts() {
                this.products = []
                const categoryDocRef = db
                    .collection('categories')
                    .doc(localStorage.getItem('categoryId'));

                db
                    .collection('products')
                    .where('category', '==', categoryDocRef)
                    .get()
                    .then((qs) => {
                        qs.forEach(doc => {
                            this
                                .products
                                .push({
                                    id: doc.id,
                                    data: doc.data()
                                })
                        })
                    });
            }
        },
        watch : {
            $route() {
                this.fetchProducts()
            }
        }
    }
</script>

<style>
.Products {
    padding: 3rem 1rem;
}
.Products__header {
    display: flex;
    justify-content: center;
}
.Products__list {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

</style>