<template>
    <div class="advice">
        <h1 class="advice__header">Växtråd</h1>
        <v-expansion-panels accordion v-if="questions.length > 0">
            <v-expansion-panel
                v-for="(question, index) in questions"
                :key="index"
                class="advice__questionWrapper">
                <v-expansion-panel-header class="advice__questionHeader">
                    <p>{{question.title}}</p>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div
                        v-for="body in question.body"
                        :key="body"
                        class="advice__questionBody"
                        v-html="body"></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="advice__emptyState">
            <v-icon size="144">mdi-file-find</v-icon>
            <h3>Inget innehåll än!</h3>
        </div>
    </div>
</template>

<script>
    import db from '../../firebase';

    export default {
        data : () => ({questions: []}),
        created : function () {
            try {
                db
                    .collection('advice')
                    .get()
                    .then(qs => {
                        qs.forEach(doc => {
                            this
                                .questions
                                .unshift(doc.data())
                        })
                    })
            } catch (error) {
                console.log(error)
            }
        }
    }
</script>

<style>
    @media only screen and (max-width: 768px) {
        .advice {
            max-width: 95% !important
        }
    }
    .advice {
        width: auto;
        max-width: 55%;
        padding: 2rem 1rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .advice__header {
        text-align: center;
        margin: 3rem 0 2rem;
    }
    .advice__questionHeader {
        font-weight: 600 !important;
        font-size: 1rem;
    }
    .advice__questionHeader p {
        margin-bottom: 0 !important;
    }
    .advice__questionWrapper {
        padding: 0.875rem 1.5rem;
        margin: 1rem 0;
    }
    .advice__questionBody {
        margin: 0.875rem 0;
    }
    .advice__emptyState {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>