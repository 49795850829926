<template>
    <v-footer dark padless>
        <v-card flat tile class="footer__card-wrapper text-center">
            <v-card-text>
                <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon @click="openLink(icon.url)">
                    <v-icon size="24px">
                        {{ icon.icon }}
                    </v-icon>
                </v-btn>
            </v-card-text>

            <v-card-text class="white--text pt-0">
                <div class="mt-4">
                    <h2>
                        Varför välja oss? 
                    </h2>
                    <div class="footer__aboutWrapper">
                        <span class="footer__aboutWrapperItem">
                            <v-icon class="footer__aboutIcon">mdi-hand-heart</v-icon>
                            <div class="footer__aboutText">
                                Personlig service
                            </div>
                        </span>
                        <span class="footer__aboutWrapperItem">
                            <v-icon class="footer__aboutIcon">mdi-truck-fast</v-icon>
                            <div class="footer__aboutText">
                                Snabba leveranser
                            </div>
                        </span>
                        <span class="footer__aboutWrapperItem">
                            <v-icon class="footer__aboutIcon">mdi-flower</v-icon>
                            <div class="footer__aboutText">
                                kvalitativa produkter
                            </div>
                        </span>
                    </div>
                </div>
                Säsongsöppet 15 mars - 30 juni och 1 oktober - 23 december
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }}
                —
                <strong>Isacsons Trädgårdshandel AB</strong>
                <p>Möbelgatan 4 43133 Mölndal 031-271111</p>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
    export default {
        name : 'Footer',
        data : () => ({
            icons: [{icon: 'mdi-facebook', url:'https://www.facebook.com/isacsonstradgard/'}, {icon: 'mdi-instagram', url: 'https://www.instagram.com/isacsonstradgard/'}]
        }),
        methods: {
            openLink(url) {
                window.open(url, "_blank")
            }
        }
    }
</script>

<style>
.footer__card-wrapper {
    width: 100%
}
.footer__aboutWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem;
}
.footer__aboutWrapperItem {
    margin: 0 2rem
}
.footer__aboutIcon {
    font-size: 4rem !important;
}
.footer__aboutText {
    margin: 1rem 0;
    font-size: 1rem;
}
</style>