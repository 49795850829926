<template>
    <v-app>
        <AppBar/>
        <v-main style="min-height: 1000px; padding-top:224px">
            <div class="banner">Fri upphämtning i butik. Hemleverans till södra/västra göteborg, mölndal & kungsbacka 150:-</div>
            <router-view/>
        </v-main>
        <div class="landingPage__informationText">
        <v-alert v-model="alert" dismissible elevation="5" transition="scale-transition" outlined>
            <h3 class="headline">Flytt!</h3>
            <div>
                Sommaren 2020 fick vi besked om att vi behövde flytta ut från växthusen, så
                sedan Oktober hittar ni oss på Möbelgatan 4! 
                <br><br> 
                Varmt välkomna tillbaka!
            </div>
        </v-alert>
        </div>
        <Footer/>
    </v-app>
</template>

<script>
    import AppBar from './components/AppBar';
    import Footer from './components/Footer';

    export default {
        name : 'App',
        components : {
            AppBar,
            Footer
        },
        data: () => ({alert: false}),
        created() {
            const {current} = this.$router.history
            this.$router.push(current.fullPath)
            const showAlert = localStorage.getItem('showAlert')
            if (showAlert === null) {
                setTimeout(() => {
                    this.alert = true
                }, 400)
            }
        },
        watch: {
            alert(val) {
                console.log(val)
                if (!val) {
                    localStorage.setItem('showAlert', false)
                }
            }
        }
    };
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
#app {
    font-family: 'Josefin Sans', sans-serif;
}
.landingPage__informationText {
    border-radius: 0.25rem;
    position: fixed;
    z-index: 10000;
    bottom: 2rem;
    right: 1rem;
    text-align: center;
    max-width: 34rem;
}
.landingPage__informationText .v-alert {
    background-color: white !important;
    margin-bottom: 0px !important
}
.banner {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  color: rgba(255,255,255, 0.89);
  position: static;
  min-height: 3rem;
  background-color: rgba(49,54,56,1.0); 
  width: 100%;
  z-index: 1;
}
</style>