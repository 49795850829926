import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function removeItem(state, productItem) {
    let remainingProducts = []
    state.products.forEach(item => {
        if (item.id !== productItem.id) {
            remainingProducts.push(item) 
        } else {
            if (item.selectedColorObject.code !== productItem.selectedColorObject.code) {
                remainingProducts.push(item)
            }
        }
    })
    state.products = remainingProducts
}

function setCheckoutStorage(state) {
  return localStorage.setItem('checkoutState', JSON.stringify(state.products))
}

export default new Vuex.Store({
    state: {
        products: [],
        currentCategoryId: undefined
    },
    mutations: {
        rebuildCheckout(state, payload) {
          state.products = payload
        },
        addToCart(state, payload) {
            const {products} = state

            if (products && products.length > 0) {
                const objIndex = state
                    .products
                    .findIndex(obj => obj.id === payload.id)
                if (objIndex !== -1) {
                    const currentCheckoutItem = state.products[objIndex]
                    if (currentCheckoutItem.selectedColorObject && payload.selectedColorObject) {
                        if (currentCheckoutItem.selectedColorObject.code === payload.selectedColorObject.code) {
                            state.products[objIndex].quantity += 1
                        } else {
                            state
                            .products
                            .unshift({
                                ...payload,
                                quantity: 1
                            }) 
                        }
                    } else {
                        state.products[objIndex].quantity += 1
                    }
                } else {
                    state
                        .products
                        .unshift({
                            ...payload,
                            quantity: 1
                        })
                }
            } else {
                state.products = [{...payload, quantity: 1}]
            }
            setCheckoutStorage(state)
        },
        removeFromCart(state, payload) {
            removeItem(state, payload)
            setCheckoutStorage(state)
        },
        async setCategoryId(state, payload) {
            state.currentCategoryId = payload
        },
        increaseProductQuantity(state, payload) {
                const objIndex = state
                    .products
                    .findIndex(obj => obj.id === payload.id && obj.selectedColorObject.code === payload.selectedColorObject.code)
                state.products[objIndex].quantity += 1
            setCheckoutStorage(state)
        },
        decreaseProductQuantity(state, payload) {
            const objIndex = state
                .products
                .findIndex(obj => obj.id === payload.id && obj.selectedColorObject.code === payload.selectedColorObject.code)
            state.products[objIndex].quantity -= 1
            if (state.products[objIndex].quantity === 0) {
                removeItem(state, payload)
            }
            setCheckoutStorage(state)
        },
        clearCheckout(state) {
            state.products = []
        }
    }
})
