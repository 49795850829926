import axios from 'axios'

const api = axios.create({timeout: 5000})

export function sendEmail (params) {
  const response = api.get('/api/postmaster', { params } )
  if (!response) {
    throw new Error('could not send email')
  }
  return response
}